<template>
    <div>
        <Row class="table-title p-t-2">
            <i-col span="3">编号</i-col>
            <i-col span="3">类型</i-col>
            <i-col span="4">所属产品</i-col>
            <i-col span="4">所属线路</i-col>
            <i-col span="4">投放日期</i-col>
            <i-col span="3">采购天数</i-col>
            <i-col span="3">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="3">{{resource.resourceCode}}</i-col>
            <i-col span="3">{{resource.stationName}}</i-col>
            <i-col span="4">{{resource.productskuName}}</i-col>
            <i-col span="4">{{resource.assetName}}</i-col>
            <i-col span="4">{{resource.startDate}}至{{resource.endDate}}</i-col>
            <i-col span="3">{{formatPublishDay(resource.startDate, resource.endDate)}}</i-col>
            <i-col span="3">
              <a @click="handleReplaceResource(resource)">替换</a>
              <a class="m-l-5 delete" v-if="!customskuId" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator  @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showReplace" width="1000" title="资源替换" :footer-hide="true">
            <replaceMetro  :params="toReplace"  v-if="showReplace" @on-update="handleReplaced"></replaceMetro>
        </Modal>

    </div>
</template>

<script>
import replaceMetro from '../common/ReplaceMetro'

import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { deleteResource } from '@/api/order/purchase'
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay } from '@/utils/dateFormat'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    replaceMetro
  },
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,

      showReplace: false,
      toReplace: {}
    }
  },
  mounted () {
    this.initPageData()
    this.$emit('on-update-map', [])
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.customskuId = this.customskuId || undefined
      getProductDetailPageByOrder(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleReplaceResource (resource) {
      this.toReplace = Object.assign({}, resource, { orderId: this.params.orderId, categoryId: this.params.categoryId })
      this.showReplace = true
    },
    handleReplaced () {
      this.showReplace = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatPublishDay (startDate, endDate) {
      return GetPublishDay(startDate, endDate)
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }

      })
    },
    handleDelete (resource) {
      const data = { orderId: this.params.orderId, startDate: resource.startDate, endDate: resource.endDate, resourceId: resource.resourceId }
      deleteResource(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', data)
        }
      })
    }

  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  }
}
</script>
